/**
 *
 * Default tracking of google analytics or tag manager
 *
 */
(function(){
	'use strict';

	var globalData;
	function pushDataSet(scope){
		if(typeof dataLayer !== "undefined"){
			var res = {};
			if(typeof globalData == "undefined"){
				globalData = JSON.parse(document.body.getAttribute("data-tag"));
			}

			for (var attrname in globalData) { res[attrname] = globalData[attrname]; }
			for (var attrname in scope) { res[attrname] = scope[attrname]; }

			dataLayer.push(res);
		}
	}

	window.successFormSubmit = function(form){
		var eventName = form.getAttribute("data-tag-name");
		if(eventName){
			pushDataSet({
				eventAction: eventName,
				event: 'submit'
			});
		}
	}
	
	var clickCustoms = document.querySelectorAll("button[data-tag-name], a[data-tag-name]");
	for (var i = 0; i < clickCustoms.length; i++) {
		clickCustoms[i].addEventListener("click", function(ev){
			pushDataSet({
				event: 'click',
				eventAction: this.getAttribute("data-tag-name"),
				eventValue: this.href
			});
		});
	}

	/*----------  Track target=_blank  ----------*/
	var outboundlinks = document.querySelectorAll("a[target=_blank]");
	for (var i = outboundlinks.length - 1; i >= 0; i--) {
		var link = outboundlinks[i];
		link.addEventListener("click", trackOutboundLinks);
	};

	function trackOutboundLinks(ev){
		pushDataSet({
			event: 'click',
			eventAction: 'click.outbound',
			eventValue: this.href
		});
	}

	/*----------  Track mailto  ----------*/
	var mailLinks = document.querySelectorAll("a[href^='mailto']");
	for (var i = mailLinks.length - 1; i >= 0; i--) {
		var link = mailLinks[i];
		link.addEventListener("click", trackMailLinks);
	};

	function trackMailLinks(ev){
		pushDataSet({
			event: 'click',
			eventAction: 'click.mail',
			eventValue: this.href
		});
	}

	/*----------  Track tel  ----------*/
	var telLinks = document.querySelectorAll("a[href^='tel']");
	for (var i = telLinks.length - 1; i >= 0; i--) {
		var link = telLinks[i];
		link.addEventListener("click", trackTelLinks);
	};

	function trackTelLinks(ev){
		pushDataSet({
			event: 'click',
			eventAction: 'click.telefoon',
			eventValue: this.href
		});
	}
})();