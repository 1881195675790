var forms = document.querySelectorAll("[data-form-source=formmanagement]");

Array.prototype.forEach.call(forms, function (form, i) {
    var currentClasses = [];
    form.addEventListener('submit', function(ev){
        sendForm(ev, this, currentClasses);
    }, false);
});

function addToResponseNode(form, msg, classname, currentClasses) {
    var responseNode = document.getElementById(form.dataset.responseNode);
    var index = [];
    currentClasses.forEach(function(val, i){
        responseNode.classList.remove(val);
        index.push(i);
    });
    responseNode.innerHTML = msg;
    responseNode.classList.add(classname);
    index.forEach(function(val){
        currentClasses.splice(val);
    });
}

function sendForm(ev, form, currentClasses){
    ev.preventDefault();
    var formData = new FormData(form);
    var request = new XMLHttpRequest();
    request.open('POST', '/submitform', true);
    request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    request.onload = function() {
        if (request.status >= 200 && request.status < 400) {
            // Success!
            var rsp = request.responseText;
            rsp = JSON.parse(rsp);

            if(rsp.status){
                addToResponseNode(form, rsp.message, "success", currentClasses); 
                form.reset();
                if(typeof successFormSubmit != "undefined"){
                    successFormSubmit(form);
                }
            } else {
                addToResponseNode(form, rsp.message, "error", currentClasses);
            }
        } else {
            addToResponseNode(form, "Systeemfout opgetreden.", "error", currentClasses);
        }
    };
    request.onerror = function(){
        addToResponseNode(form, "Serverfout opgetreden. ", "error", currentClasses);
    };
    request.send(formData);
}