require("./..\\..\\bower_components\\svgxuse\\svgxuse.js");
require("./_tracking.js");
require("./Modules/_FormManagement.js");

var navMob = document.getElementById("nav-mob");
var menuList = document.querySelector("nav ul");
var className = "open";

navMob.addEventListener("click", toggleMenu);

function toggleMenu(){
    if (menuList.classList) {
        menuList.classList.toggle(className);
    } else {
        var classes = menuList.className.split(' ');
        var existingIndex = classes.indexOf(className);

        if (existingIndex >= 0)
            classes.splice(existingIndex, 1);
        else
            classes.push(className);

        menuList.className = classes.join(' ');
    }
}